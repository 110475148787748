@font-face {
    font-family: 'lato';
    src: url('lato-regular.woff2') format('woff2'),
         url('lato-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lato';
    src: url('lato-black.woff2') format('woff2'),
         url('lato-black.woff') format('woff');
    font-weight: 900;
}

@font-face {
    font-family: 'lato';
    src: url('lato-bold.woff2') format('woff2'),
         url('lato-bold.woff') format('woff');
    font-weight: 700;
}

@font-face {
    font-family: 'lato';
    src: url('lato-light.woff2') format('woff2'),
         url('lato-light.woff') format('woff');
    font-weight: 300;
}

/* fontello */

@font-face {
  font-family: 'icones';
  src: url('icones.woff2') format('woff2'),
       url('icones.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icones";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;

  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
 
.icon-location:before { content: '\e800'; } /* '' */
.icon-phone:before { content: '\e801'; } /* '' */
.icon-search:before { content: '\e802'; } /* '' */
.icon-phone-2:before { content: '\e803'; } /* '' */
.icon-plus:before { content: '\e804'; } /* '' */
.icon-download-1:before { content: '\e805'; } /* '' */
.icon-upload:before { content: '\e806'; } /* '' */
.icon-print:before { content: '\e807'; } /* '' */
.icon-adjust:before { content: '\e808'; } /* '' */
.icon-zoom-out:before { content: '\e809'; } /* '' */
.icon-zoom-in:before { content: '\e80a'; } /* '' */
.icon-down-open:before { content: '\e80b'; } /* '' */
.icon-left-open:before { content: '\e80c'; } /* '' */
.icon-right-open:before { content: '\e80d'; } /* '' */
.icon-right-1:before { content: '\e80e'; } /* '' */
.icon-right:before { content: '\e80f'; } /* '' */
.icon-up-open:before { content: '\e810'; } /* '' */
.icon-eye:before { content: '\e811'; } /* '' */
.icon-ok:before { content: '\e812'; } /* '' */
.icon-plus-1:before { content: '\e813'; } /* '' */
.icon-user:before { content: '\e814'; } /* '' */
.icon-play:before { content: '\e815'; } /* '' */
.icon-meteo:before { content: '\e816'; } /* '' */
.icon-quote:before { content: '\e817'; } /* '' */
.icon-clock:before { content: '\e818'; } /* '' */
.icon-th-list:before { content: '\e819'; } /* '' */
.icon-download:before { content: '\f02e'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-mail:before { content: '\f0e0'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-info:before { content: '\f129'; } /* '' */
.icon-youtube:before { content: '\f167'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-share:before { content: '\f1e0'; } /* '' */
.icon-pinterest:before { content: '\f231'; } /* '' */

.icon-lg {
    font-size: 2em;
    line-height: .75em;
    vertical-align: -.0667em;
}